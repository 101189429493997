<script lang="ts" setup>
const props = defineProps<{
  modelValue: string
  placeholder?: string
}>()
defineEmits(['update:modelValue', 'search', 'keyup'])

const { t } = useI18n()
const label = computed(() => props.placeholder ?? t('search.form.button'))
</script>
<template>
  <div class="search">
    <Input
      :value="modelValue"
      name="search"
      class="input"
      :required="false"
      :label="label"
      autocomplete="off"
      @input="$emit('update:modelValue', $event)"
      @keyup="$emit('keyup', $event)"
    />
    <button @click="$emit('search', $event)">
      <Icon name="search" />
    </button>
  </div>
</template>
<style scoped lang="scss">
.search {
  display: grid;
  grid-template-columns: 1fr auto;
  --button-width: 60px;

  :deep(.input) {
    grid-column: 1 / span 2;
    grid-row: 1 / span 1;

    :deep(.formkit-input) {
      padding-right: var(--button-width);
    }
  }
}

button {
  grid-column: 2 / span 1;
  grid-row: 1 / span 1;
  z-index: 1;
  width: var(--button-width);
  padding-right: 24px;
  text-align: right;
}
</style>
